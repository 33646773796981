
import Vue from "vue";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
	IIngredient,
	IIngredientAllergen,
	IIngredientDataReq,
	IIngredientRES,
	EMPTY_INGREDIENT,
	IIngredientCategory,
} from "@common/ingredient";
import { IProductionStandardTime, EMPTY_PRODUCTION_STANDARD_TIME } from "@common/productionStandardTime";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import router from "@/router";
import IframeDialog from "@/components/common/IframeDialog.vue";
import _ from "lodash";

export default Vue.extend({
	name: "EditIngredient",
	components: {
		"iframe-dialog": IframeDialog
	},
	data: () => ({
		process: false as boolean,
		ingredient: EMPTY_INGREDIENT as IIngredient,
		oldIngredient: EMPTY_INGREDIENT as IIngredient,
		productionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,
		oldProductionStandardTime: EMPTY_PRODUCTION_STANDARD_TIME as IProductionStandardTime,
		ingredientAllergens: [] as IIngredientAllergen[],
		img: {
			data: "",
			delete: false
		},
		video: {
			data: "",
			delete: false
		},
		pdfTechnicalSheet: {
			data: "",
			delete: false
		},
		pdfInfoPoster: {
			data: "",
			delete: false
		},
		ingredientCategories: [] as IIngredientCategory[],
	}),
	created: async function () {
		this.store.dispatch.changeAppTitle("Edit ingredient");

		if (this.store.getters.ingredientCategories.length === 0) {
			await this.store.dispatch.fetchIngredientCategories();
		}

		this.ingredientCategories = JSON.parse(JSON.stringify(this.store.getters.ingredientCategories));
		this.ingredientCategories.map((category) => {
			category.name = `(${category.id}) ${category.name}`;

			return category;
		});

		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchIngredients();
		}

		if (this.store.getters.productionStandardTimes.length === 0) {
			await this.store.dispatch.fetchProductionStandardTimes();
		}

		for (const ingredient of this.store.getters.ingredients) {
			if (ingredient.id === this.$route.query.id) {
				this.oldIngredient = ingredient;
				this.ingredient = { ...ingredient };
			}
		}

		for (const time of this.store.getters.productionStandardTimes) {
			if (time.id === this.$route.query.id) {
				this.oldProductionStandardTime = _.cloneDeep(time);
				this.productionStandardTime = _.cloneDeep(time);
				break;
			} else if (time === undefined) {
				this.productionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.ingredient.name,
					id: this.ingredient.id
				};
				this.oldProductionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.ingredient.name,
					id: this.ingredient.id
				};
			} else {
				this.productionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.ingredient.name,
					id: this.ingredient.id
				};
				this.oldProductionStandardTime = {
					minimumQuantity: 0,
					fixedValue: 0,
					variableValue: 0,
					miseEnPlace: 0,
					productId: this.ingredient.name,
					id: this.ingredient.id
				};
			}
		}

		await this.fetchIngredientAllergens();
	},
	methods: {
		async fetchIngredientAllergens () {
			try {
				const data: IIngredientDataReq = {
					ingredient: this.ingredient,
					ingredientAllergens: this.ingredientAllergens
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/ingredient/allergens`,
				};
				const res: AxiosResponse<IServerRES<IIngredientAllergen[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.ingredientAllergens = res.data.payload;
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}
		},
		async saveIngredient () {
			this.process = true;

			try {
				if (this.ingredient.fixedWeight?.toString() === "") {
					this.ingredient.fixedWeight = null;
				}

				const data: IIngredientDataReq = {
					ingredient: this.ingredient,
					ingredientAllergens: this.ingredientAllergens,
					imgHash: this.img,
					videoHash: this.video,
					pdfTechnicalSheet: this.pdfTechnicalSheet,
					pdfInfoPoster: this.pdfInfoPoster
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/ingredient/update`,
				};
				const res: AxiosResponse<IServerRES<IIngredientRES>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					if (res.data.payload.succeeded) {
						this.store.dispatch.editIngredient({
							old: this.oldIngredient,
							new: res.data.payload.ingredient
						});
					}
				} else {
					// TODO ERROR DISPLAY
					console.error(new Error(res.data.payload.message));
				}
			} catch (err) {
				// TODO ERROR DISPLAY
				console.error(err);
			}

			try {
				const data: IProductionStandardTime = {
					productId: this.ingredient.name,
					id: this.ingredient.id,
					minimumQuantity: this.productionStandardTime.minimumQuantity,
					fixedValue: this.productionStandardTime.fixedValue,
					variableValue: this.productionStandardTime.variableValue,
					miseEnPlace: 0
				};
				if (this.store.getters.productionStandardTimes.find((time) => time.id === this.ingredient.id)) {
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/production-standard-time/update`,
					};
					const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						this.store.dispatch.editProductionStandardTime({
							old: this.oldProductionStandardTime,
							new: data
						});
					} else {
						console.error(new Error(res.data.payload.message));
					}
				} else {
					const options: AxiosRequestConfig = {
						method: "POST",
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						},
						data,
						url: `${this.store.getters.serverURL}/production-standard-time/add`,
					};
					const res: AxiosResponse<IServerRES<IProductionStandardTime>> = await axios(options);
					if (res.data.err === ServerError.NO_ERROR) {
						this.store.dispatch.addProductionStandardTime(data);
					} else {
						console.error(new Error(res.data.payload.message));
					}
				}
			} catch (err) {
				console.error(err);
			}

			router.push({ path: "/ingredients" });
		},
		fileChange (e: Event) {
			const target = e.target as HTMLInputElement;

			if (target.files !== null &&
							target.files.length) {
				switch (target.id) {
					case ("pdf_technical_sheet_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdfTechnicalSheet.data = result;
								}
							};
						}
						break;
					case ("pdf_info_poster_file"):
						if (target.files[0]) {
							const pdfReader: FileReader = new FileReader();
							pdfReader.readAsDataURL(target.files[0]);
							pdfReader.onload = async () => {
								const result: string|undefined = pdfReader.result?.toString().split(",")[1];
								if (result) {
									this.pdfInfoPoster.data = result;
								}
							};
						}
						break;
					case ("img_file"):
						if (target.files[0]) {
							const imgReader: FileReader = new FileReader();
							imgReader.readAsDataURL(target.files[0]);
							imgReader.onload = async () => {
								const result: string|undefined = imgReader.result?.toString().split(",")[1];
								if (result) {
									this.img.data = result;
								}
							};
						}
						break;
					case ("video_file"):
						if (target.files[0]) {
							const videoReader: FileReader = new FileReader();
							videoReader.readAsDataURL(target.files[0]);
							videoReader.onload = async () => {
								const result: string|undefined = videoReader.result?.toString().split(",")[1];
								if (result) {
									this.video.data = result;
								}
							};
						}
						break;
				}
			} else if (target.files !== null &&
							target.files.length === 0) {
				switch (target.id) {
					case ("pdf_technical_sheet_file"):
						this.pdfTechnicalSheet.data = "";
						break;
					case ("pdf_info_poster_file"):
						this.pdfTechnicalSheet.data = "";
						break;
					case ("img_file"):
						this.img.data = "";
						break;
					case ("video_file"):
						this.video.data = "";
						break;
				}
			}
		},
		async viewTechnicalSheetPDF () {
			const URL = `${this.store.getters.serverURL}/ingredient/files/${this.ingredient.id}/${this.ingredient.id}-technical-sheet.pdf?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Ingredient Technical Sheet PDF", URL);
		},
		removeTechnicalSheetPDF () {
			this.ingredient.pdfTechnicalSheetHash = "";
			this.pdfTechnicalSheet.delete = true;
		},
		async viewInfoPosterPDF () {
			const URL = `${this.store.getters.serverURL}/ingredient/files/${this.ingredient.id}/${this.ingredient.id}-info-poster.pdf?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Ingredient Info Poster PDF", URL);
		},
		removeInfoPosterPDF () {
			this.ingredient.pdfInfoPosterHash = "";
			this.pdfInfoPoster.delete = true;
		},
		async viewIMG () {
			const URL = `${this.store.getters.serverURL}/ingredient/files/${this.ingredient.id}/${this.ingredient.id}-image.png?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Ingredient Image", URL);
		},
		removeIMG () {
			this.ingredient.imgHash = "";
			this.img.delete = true;
		},
		async viewVideo () {
			const URL = `${this.store.getters.serverURL}/ingredient/files/${this.ingredient.id}/${this.ingredient.id}-video.mp4?token=${localStorage.getItem("token")}`;
			await (this.$refs.iframeDialog as InstanceType<typeof IframeDialog>).open("Ingredient Video", URL);
		},
		removeVideo () {
			this.ingredient.videoHash = "";
			this.video.delete = true;
		}
	},
});
